<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/cursos-configuracion">Configuración Cursos</a></li>
                <li><a routerLink="/titulos-listado">Titulos</a></li>
                <li>Alta Titulo</li>
            </ul>
            <h2>Alta Titulo</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="post()">

                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-xs-12">

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Nombre</mat-label>
                                    <input formControlName="nombre" matInput placeholder="Nombre" autocomplete="off">
                                </mat-form-field>

                                <mat-form-field appearance="fill" [style.width.%]=100>
                                    <mat-label>Disciplina</mat-label>
                                    <mat-select formControlName="disciplina">
                                        <mat-option *ngFor="let disciplina of disciplinas" value="{{ disciplina._id }}">{{ disciplina.nombre }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Nivel</mat-label>
                                    <input disabled formControlName="nivel" type="number" matInput placeholder="Ingrese nivel" autocomplete="off">
                                </mat-form-field>

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Vigencia en Cant. de Meses</mat-label>
                                    <input type="number" formControlName="mesesVigencia" matInput autocomplete="off">
                                </mat-form-field>

                            </div>

                            <div class="col-lg-6 col-md-12 col-xs-12">

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Carnet</mat-label>
                                    <input formControlName="carnet" matInput autocomplete="off">
                                </mat-form-field>

                                <!-- <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Diseño</mat-label>
                                    <input formControlName="disenio" matInput autocomplete="off">
                                </mat-form-field> -->

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Estampilla</mat-label>
                                    <input formControlName="estampilla" matInput autocomplete="off">
                                </mat-form-field>
<!-- 
                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Plantilla</mat-label>
                                    <input formControlName="plantilla" matInput autocomplete="off">
                                </mat-form-field> -->

                                <div>
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Plantilla de Título</mat-label>
                                        <mat-select formControlName="plantillaTituloTemplate" required>
                                            <mat-option *ngFor="let tipo of titulos" [value]="tipo.tipo">{{ tipo.descripcion }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="form.get('plantillaTituloTemplate').invalid && form.get('plantillaTituloTemplate').touched" class="error-message">Selecciona un tipo de título.</div>
                                </div>

                                <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                    <mat-label>Observaciones</mat-label>
                                    <input formControlName="observaciones" matInput autocomplete="off">
                                </mat-form-field>

                                <mat-slide-toggle formControlName="activo">Activo</mat-slide-toggle>

                            </div>
                        </div>

                        <hr>

                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button" routerLink="/cursos-configuracion" class="btn btn-warning">Cancelar</button>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <button [disabled]="!form.valid" type="submit">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>