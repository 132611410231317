import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { Router } from '@angular/router';
import { MediaService } from '../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { ConfigBeginService } from 'src/app/services/shared/config-begin.service';
import { ConfiguracionApp } from 'src/app/interfaces/interface-bakend/app-web-config/app-web-config.interfaces';
import { APP_CONFIG_CURSOS, IAppCursos } from 'src/app/app.config/app.config-cursos';

@Component({
  selector: 'app-titulo-alta',
  templateUrl: './titulo-alta.component.html',
  styleUrls: ['./titulo-alta.component.scss']
})
export class TituloAltaComponent implements OnInit {

  flagFormaValido = false;
  form: FormGroup;
  disciplinas: Disciplina[] = [];
  plantillas: string[] = [];
  titulos: object;

  constructor(
      private fb: FormBuilder,
      private cursosService: CursosService,
      private guiMsjService: GuiMsjService,
      private configBeginService: ConfigBeginService,
      @Inject(APP_CONFIG_CURSOS) private iAppCursos: IAppCursos,
      private router: Router){
      this.titulos = this.iAppCursos.titulos;
      this.createForm();
      this.createListenersStateForm();
      this.getDisciplinas();
    }

    ngOnInit(): void {
    }

    async getConfiguracionGral() {
      let conf: ConfiguracionApp;
      conf = await this.configBeginService.getConfiguracionGral();
      this.plantillas = conf.plantillas;
   }

    async getDisciplinas(){
      await this.cursosService.getDisciplinas().then( (disciplinas: Disciplina[]) => {
        this.disciplinas = disciplinas;
      });
    }

    async createForm(){
      this.form = this.fb.group({
        nombre: new FormControl('',  [ Validators.required]),
        disciplina: new FormControl('',  [Validators.required]),
        nivel: new FormControl(''),
        activo: new FormControl(true),
        mesesVigencia: new FormControl('', [Validators.required]),
        carnet: new FormControl(''),
        disenio: new FormControl(''),
        estampilla: new FormControl(''),
        observaciones: new FormControl('', ),
        plantilla: new FormControl('', ),
        plantillaTituloTemplate: new FormControl('', )
      });
    }

    async setDataForma(): Promise<Titulo>{

      const data: Titulo = {
        nombre: this.form.value.nombre,
        disciplina: this.form.value.disciplina,
        activo: this.form.value.activo,
        carnet: this.form.value.carnet || "",
        observaciones: this.form.value.observaciones || "",
        disenio: this.form.value.disenio || "",
        nivel: this.form.value.nivel,
        mesesVigencia: this.form.value.mesesVigencia,
        estampilla: this.form.value.estampilla || "",
        icono: "",
        plantilla: "",
        plantillaTituloTemplate: this.form.value.plantillaTituloTemplate
      };

      return data;
    }

    async post(){
      await this.guiMsjService.msjFormSubmit('Guardando');
      await this.cursosService.postTitulo(await this.setDataForma()).then( async (titulo: Titulo) => {
          await this.guiMsjService.msjFormSubmit('DatosGuardados');
          this.router.navigate(['/titulos-listado']);
          return;
      });
    }

    /* MÉTODOS PARA VALIDACIÓN DE FORMULARIO */
    get nombreNoValido() {
      return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    }

    get introduccionNoValida() {
      return this.form.get('introduccion').invalid && this.form.get('introduccion').touched;
    }

    get descripcionGralNoValida() {
      return this.form.get('descripcionGral').invalid && this.form.get('descripcionGral').touched;
    }

    get cantidadHorasNoValida() {
      return this.form.get('cantidadHoras').invalid && this.form.get('cantidadHoras').touched;
    }

    get fechaInicioNoValida() {
      return this.form.get('fechaInicio').invalid && this.form.get('fechaInicio').touched;
    }

    get fechaFinNoValida() {
      return this.form.get('fechaFin').invalid && this.form.get('fechaFin').touched;
    }

    get precioNoValido() {
      return this.form.get('precio').invalid && this.form.get('precio').touched;
    }

  createListenersStateForm() {
      return this.form.statusChanges.subscribe( async status => {
        //  console.log(this.form);
      });
  }
}
