import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CursoAlumno } from 'src/app/interfaces/interface-bakend/cursos/cursoAlumno.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cursos-listado-alumno-cursos',
  templateUrl: './cursos-listado-alumno-cursos.component.html',
  styleUrls: ['./cursos-listado-alumno-cursos.component.scss']
})
export class CursosListadoAlumnoCursosComponent implements OnInit, AfterViewInit {

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] =
    [
      'nroSolicitud', 'apellidoPersona', 'nombrePersona', 'email', 'curso', 'precio', 'fechaInscripcion', 'aprobado'
      // 'nroSolicitud', 'apellidoPersona', 'nombrePersona', 'email', 'curso', 'precio', 'fechaInscripcion', 'aprobado', 'fechaAprobacion', 'desaprobado', 'fechaDesaprobado', 'finalizado'
    ];
  dataSource: any;

  /* Fin Data Table*/
  usuarioLoad = false;
  flagLoaded = false;

  cursoAlumno: CursoAlumno;

  constructor(
    private cursosService: CursosService,
    private perfilesService: PerfilesService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.flagLoaded = true;
  }

  async loadDataTable() {
    await this.cursosService.getCursosAlumnos().then((cursos: any[]) => {
      this.formatDataTable(cursos).then((data: any[]) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
    await this.loadDataTable();
    this.flagLoaded = true;
    return;
  }

  formatDataTable(cursosAlumnos: any[]) {
    return new Promise(resolve => {
      const format: any[] = [];
      let elemento: {
        nroSolicitud: number,
        apellidoPersona: string,
        nombrePersona: string,
        email: string,
        curso: string,
        precio: number,
        fechaInscripcion: Date,
        id: string,
        aprobado: boolean,
        fechaAprobacion: Date,
        desaprobado: boolean,
        finalizado: boolean,
        fechaDesaprobado: Date,
        // fechaInicia: Date,
        // fechaFinaliza: Date,
      };
      // Ordenar por nroSolicitud
      cursosAlumnos.sort((a, b) => b.nroSolicitud - a.nroSolicitud);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cursosAlumnos.length; i++) {
        elemento = {
          nroSolicitud: cursosAlumnos[i].nroSolicitud,
          apellidoPersona: cursosAlumnos[i].alumno.usuario.apellidoPersona,
          nombrePersona: cursosAlumnos[i].alumno.usuario.nombrePersona,
          email: cursosAlumnos[i].alumno.usuario.email,
          curso: cursosAlumnos[i].curso.nombre,
          precio: cursosAlumnos[i].curso.precio,
          fechaInscripcion: cursosAlumnos[i].fechaInscripcion,
          id: cursosAlumnos[i]._id,
          aprobado: cursosAlumnos[i].aprobado,
          fechaAprobacion: cursosAlumnos[i].fechaAprobacion,
          desaprobado: cursosAlumnos[i].desaprobado,
          finalizado: cursosAlumnos[i].finalizado,
          fechaDesaprobado: cursosAlumnos[i].fechaDesaprobado
          // fechaInicia: cursosAlumnos[i].curso.fechaInicia,
          // fechaFinaliza: cursosAlumnos[i].curso.fechaFinaliza,

        };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  async aprobarCurso(estado: boolean, id: string) {
    this.cursoAlumno = await this.cursosService.getCursoAlumnoFindOne(id);
    const data: CursoAlumno = {
      curso: this.cursoAlumno.curso,
      alumno: this.cursoAlumno.alumno,
      fechaInscripcion: this.cursoAlumno.fechaInscripcion,
      fechaInicia: this.cursoAlumno.fechaInicia,
      fechaFinaliza: this.cursoAlumno.fechaFinaliza,
      fechaAprobacion: estado ? new Date() : null,
      finalizado: (estado || this.cursoAlumno.desaprobado) ? true : false,
      aprobado: estado,
      desaprobado: false,
      fechaDesaprobado: null,
    };

    await this.cursosService.updateCursoAlumnoOne(id, data).then(resp => {
      this.snackBar.open("Actualizado correctamente", "Cerrar", {
        duration: 3000
      });
      // Llamar a loadDataTable para refrescar los datos
      // this.loadDataTable();
    });
  }

  async desaprobarCurso(estado: boolean, id: string) {
    this.cursoAlumno = await this.cursosService.getCursoAlumnoFindOne(id);
    const data: CursoAlumno = {
      curso: this.cursoAlumno.curso,
      alumno: this.cursoAlumno.alumno,
      fechaInscripcion: this.cursoAlumno.fechaInscripcion,
      fechaInicia: this.cursoAlumno.fechaInicia,
      fechaFinaliza: this.cursoAlumno.fechaFinaliza,
      fechaDesaprobado: estado ? new Date() : null,
      fechaAprobacion: null,
      aprobado: false,
      finalizado: (estado || this.cursoAlumno.aprobado) ? true : false,
      desaprobado: estado
    };

    await this.cursosService.updateCursoAlumnoOne(id, data).then(resp => {
      this.snackBar.open("Actualizado correctamente", "Cerrar", {
        duration: 3000
      });
      // Llamar a loadDataTable para refrescar los datos
      this.loadDataTable();
    });
  }
}
